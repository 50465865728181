<template>
  <div class="h-100" v-if="!isLoadingAddressList">
    <div class="section">
      <div class="d-flex justify-content-center" v-if="isLoadingAddress">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="accordion" role="tablist" v-else>
        <template>
          <div
            v-b-toggle.collapse-billing
            class="collapse-btn py-2 d-flex justify-content-between"
          >
            <span class="mx-3 font-weight-bold">Billing Address</span>
            <div class="toogle">
              <span class="text-secondary mr-2"
                >{{
                  addressList.billing && addressList.billing.length > 0
                    ? addressList.billing.length
                    : "0"
                }}/3</span
              >
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                class="mr-3 closed"
              />
            </div>
          </div>
          <b-collapse id="collapse-billing" visible>
            <template
              v-if="!addressList.billing || addressList.billing.length == 0"
            >
              <div
                class="d-flex justify-content-center align-items-center w-100 mb-4"
              >
                <span class="mt-1">No Data</span>
              </div>
            </template>
            <template v-else>
              <div
                class="d-flex flex-column mx-3 my-3 p-2 detail position-relative"
                v-for="(item, index) in addressList.billing"
                :key="index"
              >
                <span class="position-absolute tags" v-if="item.is_default"
                  >Default</span
                >
                <span class="color-primary">{{
                  item.billing_address_type ? "นิติบุคคล" : "บุคคลธรรมดา"
                }}</span>
                <span class="font-weight-bold"
                  >{{ item.first_name }} {{ item.last_name }}
                  <span class="text-secondary">{{ item.telephone }}</span></span
                >
                <span class="text-secondary">{{ formatAddress(item) }}</span>
                <span class="font-weight-bold"
                  >ขนส่งโดย :<span class="ml-2">{{
                    item.shipping_type
                  }}</span></span
                >
              </div>
            </template>
          </b-collapse>
          <div class="px-2 my-2">
            <hr class="text-muted my-0" />
          </div>
        </template>

        <template>
          <div
            v-b-toggle.collapse-Contact
            class="collapse-btn py-2 d-flex justify-content-between"
          >
            <span class="mx-3 font-weight-bold">Contact Address</span>
            <div class="toogle">
              <span class="text-secondary mr-2"
                >{{
                  addressList.contact && addressList.contact.length > 0
                    ? addressList.contact.length
                    : "0"
                }}/3</span
              >
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                class="mr-3 closed"
              />
            </div>
          </div>
          <b-collapse id="collapse-Contact" visible>
            <template
              v-if="!addressList.contact || addressList.contact.length == 0"
            >
              <div
                class="d-flex justify-content-center align-items-center w-100 mb-4"
              >
                <span class="mt-1">No Data</span>
              </div>
            </template>
            <template v-else>
              <div
                class="d-flex flex-column mx-3 my-3 p-2 detail position-relative"
                v-for="(item, index) in addressList.contact"
                :key="index"
              >
                <span class="position-absolute tags" v-if="item.is_default"
                  >Default</span
                >
                <span class="font-weight-bold"
                  >{{ item.first_name }} {{ item.last_name }}
                  <span class="text-secondary">{{ item.telephone }}</span></span
                >
                <span class="text-secondary">{{ formatAddress(item) }}</span>
              </div>
            </template>
          </b-collapse>
          <div class="px-2 my-2">
            <hr class="text-muted my-0" />
          </div>
        </template>

        <template>
          <div
            v-b-toggle.collapse-shipping
            class="collapse-btn py-2 d-flex justify-content-between"
          >
            <span class="mx-3 font-weight-bold">Shipping Address</span>
            <div class="toogle">
              <span class="text-secondary mr-2"
                >{{
                  addressList.shipping && addressList.shipping.length > 0
                    ? addressList.shipping.length
                    : "0"
                }}/3</span
              >
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                class="mr-3 closed"
              />
            </div>
          </div>
          <b-collapse id="collapse-shipping" visible>
            <template
              v-if="!addressList.shipping || addressList.shipping.length == 0"
            >
              <div
                class="d-flex justify-content-center align-items-center w-100 mb-4"
              >
                <span class="mt-1">No Data</span>
              </div>
            </template>
            <template v-else>
              <div
                class="d-flex flex-column mx-3 my-3 p-2 detail position-relative"
                v-for="(item, index) in addressList.shipping"
                :key="index"
              >
                <span class="position-absolute tags" v-if="item.is_default"
                  >Default</span
                >
                <span class="font-weight-bold"
                  >{{ item.first_name }} {{ item.last_name }}
                  <span class="text-secondary">{{ item.telephone }}</span></span
                >
                <span class="text-secondary">{{ formatAddress(item) }}</span>
              </div>
            </template>
          </b-collapse>
          <div class="px-2 my-2">
            <hr class="text-muted my-0" />
          </div>
        </template>
      </div>
    </div>
    <!-- <div
      v-if="!selectTransactionId"
      class="d-flex flex-row px-2 pb-1 border-bottom"
    >
      <div class="">
        <b-dropdown id="dropdown-form" ref="dropdown" class="m-0 w-100 pr-2">
          <template #button-content>
            <font-awesome-icon :icon="['fas', 'filter']" class="mr-2" />ตัวกรอง
          </template>
          <div class="pt-0">
            <span class="px-2">
              <label for="checkbox-group-orderStatusId" class="mb-0"
                >สถานะสินค้า</label
              >
            </span>
            <b-form-group class="">
              <div class="px-2 mb-1">
                <b-form-checkbox
                  id="checkbox-group-orderStatusId-all"
                  v-model="orderFilter.orderStatusId"
                  name="checkbox-group-orderStatusId-all"
                  :value="0"
                  unchecked-value=""
                  @change="handleFilterChangeStatusALL"
                >
                  ทุกสถานะ
                </b-form-checkbox>
              </div>
              <div class="px-3">
                <b-form-checkbox
                  v-for="option in statusList"
                  :key="option.id"
                  :class="['']"
                  v-model="orderFilter.orderStatusId"
                  name="checkbox-group-orderStatusId"
                  :value="option.id"
                  @change="handleFilterChangeStatus(option.id)"
                >
                  {{ option.name }}</b-form-checkbox
                >
              </div>
            </b-form-group>
          </div>
        </b-dropdown>
      </div>
      <div class="flex-grow w-100">
        <div class="input-group">
          <input
            type="text"
            placeholder="หมายเลขคำสั่งซื้อ,ชื่อ"
            name=""
            class="form-control search"
            v-model="orderFilter.search"
            @keypress="handleFilterChangeSearch"
          />
          <div class="input-group-prepend" @click="handleFilterClickSearch">
            <span class="input-group-text search_btn">
              <font-awesome-icon :icon="['fas', 'search']" class=""
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!selectTransactionId" class="panel order-list">
      <div v-for="(item, i) in orderList.detail" :key="i">
        <ChatOrderHistoryCard
          :order="item"
          class="border-top border-bottom px-3 py-2"
          @set-transactionId="getOrderDetail"
        />
      </div>
      <infinite-loading
        spinner="spiral"
        @infinite="scrollMethod"
        ref="infiniteLoadingOrderHistory"
      >
        <div
          slot="spinner"
          class="spinner-border text-secondary p-2"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <div
        v-if="orderList.detail.length < 1 && !isLoadingList"
        class="d-flex h-100"
      >
        <span class="m-auto">ไม่มีรายการ</span>
      </div>
    </div>
    <ChatOrderListPanel
      v-if="selectTransactionId"
      :selectTransactionId="selectTransactionId"
      :userDetail="userDetail"
      :userId="userId"
      :userInfo="userInfo"
      :orderSelected="orderSelected"
      @set-buyer-info-select="val => (orderSelected.buyerInfo = val)"
      :handleFinishCreateOrder="handleFinishUpdateOrder"
      :handleShowModalCopy="handleShowModalCopy"
      :onsetOrderSelected="onsetOrderSelected"
      class="pt-2"
      isOrderSendered
      @set-select-transaction-id="val => (selectTransactionId = val)"
      :getOrderDetail="getOrderDetail"
      :handleFilterClickSearch="handleFilterClickSearch"
    /> -->
  </div>
</template>

<script>
// import ChatOrderHistoryCard from "@/components/chat/chatOrder/chatOrderHistory/ChatOrderHistoryCard.vue";
// import ChatOrderListPanel from "@/components/chat/chatOrder/chatOrderList/ChatOrderListPanel";
export default {
  name: "ChatOrderPanel",
  components: {
    // ChatOrderHistoryCard,
    // ChatOrderListPanel
  },
  props: {
    userId: {
      required: false,
      type: String
    },
    userInfo: {
      required: false,
      type: Object
    },
    isLoadingAddress: {
      required: false,
      type: Boolean
    },
    isLoadingAddressList: {
      required: false,
      type: Boolean
    },
    addressList: {
      required: false,
      type: [Array, Object]
    },
    userDetail: {
      required: false,
      type: Object
    },
    orderSelected: {
      required: false,
      type: Object
    },
    onsetOrderSelected: {
      required: false,
      type: Function
    },
    handleShowModalCopy: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      isLoadingList: false,
      textSearchInput: "",
      orderFilter: {
        userId: "",
        brandId: this.$route.query.brandID,
        orderStatusId: [0],
        chanelId: [0],
        pageNo: 0,
        perPage: 10,
        search: ""
      },
      orderList: {
        totalCount: 0,
        detail: []
      },
      selectTransactionId: null,
      statusList: [],
      chanelSelectedList: []
    };
  },
  methods: {
    formatAddress(item) {
      let string = "";
      string = `${item.address} ${item.building_name} ${item.alley} ${item.road} ${item.sub_district} ${item.district} ${item.province} ${item.zip_code} ${item.country}`;
      return string;
    },
    handleFilterChangeChenelALL: async function() {
      this.orderFilter.pageNo = 1;
      let body = { ...this.orderFilter };
      body.chanelId = [0];
      this.orderList = { detail: [] };
      this.orderFilter.chanelId = body.chanelId;
      this.isLoadingList = true;
      if (this.$refs.infiniteLoadingOrderHistory) {
        this.$refs.infiniteLoadingOrderHistory.stateChanger.reset();
      }
      await this.getOrderList(body, false);
      this.isLoadingList = false;
    },
    handleFilterChangeChenel: async function(val) {
      this.orderFilter.pageNo = 1;
      let body = { ...this.orderFilter };
      if (body.chanelId.includes(0) && val.includes(0) && val.length > 1) {
        val.shift();
      } else if (val.includes(0) && val.length > 1) {
        let valLength = val.length;
        for (let i = 0; i < valLength; i++) {
          val.shift();
        }
        val.push(0);
      }
      body.chanelId = val;
      this.orderList = { detail: [] };
      this.isLoadingList = true;
      if (this.$refs.infiniteLoadingOrderHistory) {
        this.$refs.infiniteLoadingOrderHistory.stateChanger.reset();
      }
      await this.getOrderList(body, false);
      this.isLoadingList = false;
    },
    handleFilterChangeStatusALL: async function() {
      this.orderFilter.pageNo = 1;
      let body = { ...this.orderFilter };
      body.orderStatusId = [0];
      this.orderList = { detail: [] };
      this.orderFilter.orderStatusId = body.orderStatusId;
      this.isLoadingList = true;
      if (this.$refs.infiniteLoadingOrderHistory) {
        this.$refs.infiniteLoadingOrderHistory.stateChanger.reset();
      }
      await this.getOrderList(body, false);
      this.isLoadingList = false;
    },
    handleFilterChangeStatus: async function() {
      this.orderFilter.pageNo = 1;
      let body = { ...this.orderFilter };
      let indx = body.orderStatusId.findIndex(temp => temp == 0);
      if (indx > -1) {
        body.orderStatusId.splice(indx, 1);
      }
      this.orderFilter.orderStatusId = body.orderStatusId;
      this.orderList = { detail: [] };
      this.isLoadingList = true;
      if (this.$refs.infiniteLoadingOrderHistory) {
        this.$refs.infiniteLoadingOrderHistory.stateChanger.reset();
      }
      await this.getOrderList(body, false);
      this.isLoadingList = false;
    },
    handleFilterChangeSearch: async function(evt) {
      if (evt.keyCode === 13) {
        evt.preventDefault();
        await this.handleFilterClickSearch();
      }
    },
    handleFilterClickSearch: async function() {
      this.orderList = { detail: [] };
      this.isLoadingList = true;
      this.orderFilter.pageNo = 1;
      let body = { ...this.orderFilter };
      if (this.$refs.infiniteLoadingOrderHistory) {
        this.$refs.infiniteLoadingOrderHistory.stateChanger.reset();
      }
      await this.getOrderList(body, false);
      this.isLoadingList = false;
    },
    getOrderList: async function(body, scrollFlag, $state) {
      body.userId = this.userId;
      if (body.chanelId.includes(0)) {
        body.chanelId = [];
      } else if (body.chanelId.length === 0) {
        body.chanelId = [999];
      }
      if (body.orderStatusId.includes(0)) {
        body.orderStatusId = [];
      } else if (body.orderStatusId.length === 0) {
        body.orderStatusId = [999];
      }
      await this.$axios
        .post(
          `${this.$baseUrlOrder}/api/transaction?search=${this.orderFilter.search}&take=${this.orderFilter.perPage}&page=${this.orderFilter.pageNo}`,
          {
            userGUID: this.userId,
            salesChannel: body.chanelId,
            status: body.orderStatusId
          }
        )
        // .post(`${this.$baseUrl}/api/user/order`, {
        //   userGUID: this.userId,
        //   brandId: this.orderFilter.brandId,
        //   pageNo: this.orderFilter.pageNo,
        //   pageSize: this.orderFilter.perPage
        // })
        .then(response => response.data)
        .then(response => {
          if (response.result === 1) {
            this.orderList.totalCount = response.detail.totalCount;
            if (scrollFlag) {
              if (response.detail.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                this.orderList.detail = [
                  ...this.orderList.detail,
                  ...response.detail.detail
                ];
                if ($state) $state.loaded();
              }
            } else {
              this.orderList.detail = response.detail.detail;
              if ($state) $state.loaded();
            }
          } else {
            console.error("Something went wrong");
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    getAllStatus: async function() {
      await this.$axios
        .get(this.$baseUrlOrder + "/api/filter/status")
        .then(response => {
          if (response.data.result === 1) {
            response.data.detail.shift();
            this.statusList = response.data.detail;
          } else {
            alert(response.data.message);
          }
        });
    },
    // scrollMethod($state) {
    //   if (!this.isLoadingList) {
    //     setTimeout(async () => {
    //       this.orderFilter.pageNo++;
    //       let body = { ...this.orderFilter };
    //       this.isLoadingList = true;
    //       await this.getOrderList(body, true, $state);
    //       this.isLoadingList = false;
    //     }, 500);
    //   } else {
    //     $state.reset();
    //   }
    // },
    getOrderDetail: async function(val) {
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .get(this.$baseUrl + "/api/transaction/" + val)
        .then(res => res.data)
        .then(response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.result === 1) {
            this.$emit("set-value-order-selected", response.detail);
            this.selectTransactionId = val;
          } else {
            console.error("Something went wrong");
          }
        });
    },
    handleFinishUpdateOrder() {
      this.selectTransactionId = null;
      this.handleFilterClickSearch();
    }
  },
  created: async function() {
    // this.getAllStatus();
    // this.isLoadingList = true;
    // let body = { ...this.orderFilter };
    // await this.getOrderList(body, false);
    // this.isLoadingList = false;
  }
};
</script>

<style lang="scss" scoped>
.section {
  overflow: auto;
  width: 100%;
  height: calc(100% - 200px);
}

.collapsed > .toogle .closed {
  transform: rotate(0deg);
  transition: 0.2s all;
}

.not-collapsed > .toogle .closed {
  transform: rotate(180deg);
  transition: 0.2s all;
}

.tags {
  top: 10px;
  right: 10px;
  color: #fff;
  background: #1fdea2;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.closed {
  color: #9450c2;
}

.detail {
  border: 1px solid #9c9c9c;
  border-radius: 8px;
  cursor: pointer;
}

.order-list {
  height: calc(100% - 285px);
  overflow: hidden;
  overflow-y: auto;
}

.text-secondary {
  color: #9c9c9c !important;
}

.search {
  // border-radius: 15px 0 0 15px !important;
  background-color: #f7f7f7;
  border: 1 !important;
  border-right: none;
  border-color: #cccccc;
  color: #707070 !important;
}

.search:focus {
  box-shadow: #707070 !important;
  outline: 1px !important;
}

.search_btn {
  // border-radius: 0 15px 15px 0 !important;
  background-color: #f7f7f7 !important;
  border: 1 !important;
  border-left: none;
  border-color: #cccccc;
  color: #707070 !important;
  cursor: pointer;
}

.spinner-border {
  width: 20px;
  height: 20px;
}

::v-deep .btn-secondary {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #832abf;
}

::v-deep .btn-secondary:active {
  border: 1px solid #832abf !important;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #832abf;
  background-color: #832abf;
}

::v-deep .dropdown-menu {
  min-width: 14rem;
}

@media (max-width: 767.98px) {
  .order-list {
    height: calc(100% - 178px);
  }
}
</style>
