<template>
  <div class="h-100">
    <div class="section">
      <div class="accordion p-3" role="tablist">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="filter.perPage"
          :busy="isBusy"
          show-empty
          empty-text="Empty Data"
          responsive
          class="table-main"
        >
          <template v-slot:cell(name)="data">
            <div class="text-center">
              <span>{{ data.item.name }}</span>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <div class="text-center">
              <span>{{ data.item.type }}</span>
            </div>
          </template>
          <template v-slot:cell(value)="data">
            <div class="text-center">
              <span>{{ data.item.value }}</span>
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="text-center" @click="show(data.item)">
              <span class="icon-filter color-primary p-0">
                <font-awesome-icon :icon="['fas', 'pen']"
              /></span>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-row class="no-gutters">
          <b-col class="form-inline">
            <p class="my-auto">
              Showing
              {{ showing === 1 ? showing : showing + 1 }} to {{ showingTo }} of
              {{ rows | numeral("0,0") }} Entries
            </p>
          </b-col>
          <b-col class="form-inline">
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.perPage"
              class="pagination-main"
              @change="handlePagination"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal ref="modal-edit" title="Edit Variable" centered>
      <div class="w-100">
        <InputText
          v-model="form.value"
          textFloat="Value"
          placeholder="Value"
          isRequired
          type="text"
          :isValidate="$v.form.value.$error"
          :v="$v.form.value"
        />
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-button class="btn-cancel px-4 mx-2" size="sm" @click="hide">
            Cancel
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right px-4 mx-2"
            @click="handleSubmit()"
          >
            Confirm
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ChatOrderPanel",
  components: { InputText },
  props: {
    userId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      filter: {
        page: 1,
        perPage: 10,
        search: "",
        userGUID: this.userId
      },
      form: {
        id: "",
        userGUID: "",
        variableId: "",
        name: "",
        type: "",
        value: ""
      },
      isBusy: false,
      items: [],
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "text-center"
        },
        {
          key: "type",
          label: "Type",
          class: "text-center"
        },
        {
          key: "value",
          label: "Value",
          thClass: "text-center"
        },
        {
          key: "action",
          label: "",
          thClass: "text-center"
        }
      ]
    };
  },
  validations() {
    return {
      form: {
        value: {
          required
        }
      }
    };
  },
  methods: {
    handlePagination(page) {
      this.filter.page = page;
      if (page === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.perPage;
      } else {
        this.showing = page * this.filter.perPage - this.filter.perPage;
        this.showingTo = this.showing + this.filter.perPage;
      }
      this.getCustomerList();
    },
    getCustomerList: function() {
      this.$axios
        .post(`${this.$chatBaseUrl}/Customer/CustomField/list`, this.filter)
        .then(response => response.data)
        .then(res => {
          if (res.result == 1) {
            this.items = res.detail.data;
            this.rows = this.items.length;
          }
        });
    },
    handleSubmit: async function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      await this.$axios
        .post(`${this.$chatBaseUrl}/customer/CustomField/Save`, this.form)
        .then(response => response.data)
        .then(res => {
          if (res.result == 1) {
            this.$store.commit("setMessageGlobalAlert", "แก้ไขข้อมูลสำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            this.hide();
          } else {
            this.$store.commit("setMessageGlobalAlertError", res.detail);
            this.$store.commit("setShowGlobalAlertError", true);
          }
        });
    },
    show(val) {
      this.form.value = val.value;
      this.$refs["modal-edit"].show();
    },
    hide() {
      this.$refs["modal-edit"].hide();
    }
  },
  created: async function() {
    this.getCustomerList();
  }
};
</script>

<style lang="scss" scoped>
.section {
  overflow: auto;
  width: 100%;
  height: calc(100% - 200px);
}

.icon-filter {
  cursor: pointer;
}

.text-secondary {
  color: #9c9c9c !important;
}

.spinner-border {
  width: 20px;
  height: 20px;
}

::v-deep .btn-secondary {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #832abf;
}

::v-deep .btn-secondary:active {
  border: 1px solid #832abf !important;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #832abf;
  background-color: #832abf;
}

::v-deep .dropdown-menu {
  min-width: 14rem;
}

@media (max-width: 767.98px) {
  .order-list {
    height: calc(100% - 178px);
  }
}
</style>
