<template>
  <div class="section border-top">
    <div class="accordion" role="tablist">
      <div
        v-b-toggle.collapse-profile
        class="collapse-btn py-2 d-flex justify-content-between"
      >
        <span class="mx-3 font-weight-bold">Customer Profile</span>
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="mr-3 closed"
        />
      </div>
      <b-collapse id="collapse-profile" visible>
        <div class="list">
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">เลขสมาชิก</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.member_id) }}</span>
            </b-col>
          </b-row>
          <!-- <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">รูปแบบสมาชิก</span>
            </b-col>
            <b-col cols="8">
              <div class="d-flex flex-warp">
                <span class="tags mr-2" v-if="userDetail.member_name">{{
                  userDetail.member_name
                }}</span>
                <span class="p-0" v-else>-</span>
              </div>
            </b-col>
          </b-row> -->
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title"
                >{{
                  userDetail.member_name == "Company"
                    ? "ชื่อบริษัท"
                    : "ชื่อ-นามสกุล"
                }}
              </span>
            </b-col>
            <b-col cols="8">
              <div class="d-flex flex-warp">
                <span
                  >{{ userDetail.first_name_th || "-" }}
                  {{ userDetail.last_name_th }}</span
                >
                <span class="tags mx-2" v-if="userDetail.member_name">{{
                  userDetail.member_name
                }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title"
                >{{
                  userDetail.member_name == "Company"
                    ? "เลขประจำตัวผู้เสียภาษี"
                    : "เลขบัตรประชาชน"
                }}
              </span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.id_card) }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">วันเกิด</span>
            </b-col>
            <b-col cols="8">
              <span v-if="userDetail.birthday"
                >{{ new Date(userDetail.birthday) | moment($formatDate)
                }}<span class="ml-1 text-secondary" v-if="userDetail.age_string"
                  >({{ userDetail.age_string }})</span
                ></span
              >
              <span class="p-0" v-else>-</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">เพศ</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.gender) }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">วันที่ลงทะเบียน</span>
            </b-col>
            <b-col cols="8">
              <span v-if="userDetail.created_time"
                >{{ new Date(userDetail.created_time) | moment($formatDate)
                }}<span
                  class="ml-1 text-secondary"
                  v-if="userDetail.created_string"
                  >({{ userDetail.created_string }})</span
                ></span
              >
              <span v-else>-</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">เบอร์โทรศัพท์</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.telephone) }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">อีเมล</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.email) }}</span>
            </b-col>
          </b-row>
          <!-- <b-row
            class="px-3 mb-2"
            no-gutters
            v-if="userDetail.customer_type != '4'"
          >
            <b-col cols="4">
              <span class="title">ชื่อบริษัท</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.member_name) }}</span>
            </b-col>
          </b-row> -->
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">Customer Type</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.customer_type) }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-3" no-gutters>
            <b-col cols="4">
              <span class="title">Full Address</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.address) }}</span>
            </b-col>
          </b-row>
          <!-- <b-row class="px-3 mb-3" no-gutters>
            <b-col cols="4">
              <span class="title">Social Media</span>
            </b-col>
            <b-col cols="8">
              <div
                v-if="userDetail.social && userDetail.social.length > 0"
                class="d-flex flex-column"
              >
                <div
                  class="my-1 d-flex"
                  v-for="(item, index) in userDetail.social"
                  :key="index"
                >
                  <img :src="item.banner" class="image mr-2" />
                  <span>{{ item.value }}</span>
                  <font-awesome-icon
                    @click="copyClipboard(item.value)"
                    :icon="['fas', 'copy']"
                    class="mx-2 mt-1 icon"
                  />
                </div>
              </div>
              <template v-else>
                <span>-</span>
              </template>
            </b-col>
          </b-row> -->
        </div>
      </b-collapse>
      <div class="px-2 my-2">
        <hr class="text-muted my-0" />
      </div>

      <div
        v-b-toggle.collapse-additional-question
        class="collapse-btn py-2 d-flex justify-content-between"
      >
        <span class="mx-3 font-weight-bold"
          >Additional Question
          <font-awesome-icon
            icon="edit"
            class="edit-icon"
            v-if="userDetail.customer_url"
            @click.stop="goToUrl(userDetail.customer_url)"
          />
        </span>
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="mr-3 closed"
        />
      </div>
      <b-collapse id="collapse-additional-question" visible>
        <div class="list px-3 customer-detail">
          <div
            v-for="customField of userDetail.user_custom_field"
            :key="customField.id"
          >
            <template>
              <div
                :class="[
                  customField.field_type_id == 10 ||
                  customField.field_type_id == 11
                    ? 'text-bold text-black '
                    : 'f-14',
                  { 'f-16': customField.field_type_id == 11 },
                  { 'f-18': customField.field_type_id == 10 }
                ]"
              >
                {{ customField.name }}
              </div>
              <template v-if="customField.user_answer.length > 0">
                <div v-for="answer of customField.user_answer" :key="answer.id">
                  <span v-if="customField.field_type_id == 8">
                    <b-img :src="answer.answer" class="w-100 h-100"></b-img>
                  </span>
                  <span v-else-if="customField.field_type_id == 5">
                    {{ $moment(answer.answer).format("DD MMM YYYY (HH:mm)") }}
                  </span>

                  <span v-else-if="customField.field_type_id == 7">
                    {{ $moment(answer.answer).format("DD MMM YYYY") }}
                  </span>
                  <span v-else>{{ answer.answer || "-" }} </span>
                </div>
              </template>
              <template v-else> - </template>
            </template>
          </div>
          <div
            class="d-flex justify-content-center align-items-center w-100 mb-4"
            v-if="
              !userDetail.user_custom_field ||
                userDetail.user_custom_field.length == 0
            "
          >
            <span class="mt-1">No Data</span>
          </div>
        </div>
      </b-collapse>
      <div class="px-2 my-2">
        <hr class="text-muted my-0" />
      </div>

      <div
        v-b-toggle.collapse-member
        class="collapse-btn py-2 d-flex justify-content-between"
      >
        <span class="mx-3 font-weight-bold">Member Information</span>
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="mr-3 closed"
        />
      </div>
      <b-collapse id="collapse-member" visible>
        <div class="list">
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">Member Tier</span>
            </b-col>
            <b-col cols="8">
              <span
                >{{ checkInfo(userDetail.member_level_name)
                }}<span
                  class="ml-1 text-secondary"
                  v-if="userDetail.member_level_string"
                  >({{ userDetail.member_level_string }})</span
                ></span
              >
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">Price Tier</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.price_tier) }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">คะแนนที่มี</span>
            </b-col>
            <b-col cols="8">
              <span>{{ userDetail.point_toal | numeral("0,0.00") }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">เครดิต</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.credit) }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-3" no-gutters>
            <b-col cols="4">
              <span class="title">Document</span>
            </b-col>
            <b-col cols="8">
              <span>{{ checkInfo(userDetail.documents) }}</span>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
      <div class="px-2 my-2">
        <hr class="text-muted my-0" />
      </div>

      <div
        v-b-toggle.collapse-purchase
        class="collapse-btn py-2 d-flex justify-content-between"
      >
        <span class="mx-3 font-weight-bold">Purchase Information</span>
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="mr-3 closed"
        />
      </div>
      <b-collapse id="collapse-purchase" visible>
        <div class="list">
          <b-row class="px-3 mb-2" no-gutters>
            <b-col cols="4">
              <span class="title">Total Spend</span>
            </b-col>
            <b-col cols="8">
              <span>{{ userDetail.totalspend | numeral("0,0.00") }}</span>
            </b-col>
          </b-row>
          <b-row class="px-3 mb-3" no-gutters>
            <b-col cols="4">
              <span class="title">Last Purchase</span>
            </b-col>
            <b-col cols="8">
              <span v-if="userDetail.last_purchase_date">{{
                checkInfo(userDetail.last_purchase_date) | moment($formatDate)
              }}</span>
              <span v-else>-</span>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
      <div class="px-2 my-2">
        <hr class="text-muted my-0" />
      </div>

      <div
        v-b-toggle.collapse-activity
        class="collapse-btn py-2 d-flex justify-content-between"
      >
        <span class="mx-3 font-weight-bold">Ticket</span>
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="mr-3 closed"
        />
      </div>
      <b-collapse id="collapse-activity" visible>
        <RecentActivity
          ref="ticket"
          :userId="userId"
          :is-customer="true"
          ticket-id="0"
          :userDetail="userDetail"
          :chatRoomId="chatRoomId"
        />
      </b-collapse>
      <div class="px-2 my-2">
        <hr class="text-muted my-0" />
      </div>
      <div
        v-b-toggle.collapse-note
        class="collapse-btn py-2 d-flex justify-content-between"
      >
        <span class="mx-3 font-weight-bold">Note</span>
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="mr-3 closed"
        />
      </div>
      <b-collapse id="collapse-note" visible>
        <NoteDetail
          ref="noteDetail"
          :userId="userId"
          :userDetail="userDetail"
          :chatRoomId="chatRoomId"
        />
      </b-collapse>
      <div class="px-2 my-2">
        <hr class="text-muted my-0" />
      </div>
    </div>
  </div>
  <!-- <div class="h-100" id="divChatOrderListPanel">
    <div v-if="isShowBar" class="d-flex justify-content-between px-3">
      <div class="d-flex">
        <div class="border-title"></div>
        <div class="title my-auto ml-2 font-weight-bold">รายการขาย</div>
      </div>
      <div class="d-flex">
        <b-button
          v-if="step == 0"
          @click="handleClickCreateNew"
          class="btn-create-order my-auto"
        >
          <font-awesome-icon class="text-white mr-1" icon="plus-circle" />
          สร้างใหม่
        </b-button>
        <template v-else>
          <b-badge pill variant="secondary" class="my-auto px-3 py-1">
            {{ orderSelected.statusName }}
          </b-badge>
        </template>
      </div>
    </div>
    <div v-if="isShowBar" class="d-flex mt-2 mb-2 mx-3">
      <div class="w-100 pr-1">
        <label for="channelSell" class="mb-1">ช่องทางการขาย</label>
        <b-form-select
          class=""
          value="null"
          :disabled="true"
          name="channelSell"
        >
          <template v-slot:first>
            <b-form-select-option
              v-if="
                $store.getters.getChatSocialList.length &&
                  userInfo.socialSourceId
              "
              value="null"
              disabled
            >
              {{
                $store.getters.getChatSocialList[
                  $store.getters.getChatSocialList.findIndex(
                    t => t.id == userInfo.socialSourceId
                  )
                ].name
              }}
            </b-form-select-option>
          </template>
        </b-form-select>
      </div>
      <div class="w-100 pl-1">
        <label for="branch" class="mb-1">เลือกสาขา</label>
        <b-form-select
          v-model="orderSelected.branchId"
          :options="branchLists"
          class=""
          :disabled="isOrderSendered || step === 0"
          value-field="id"
          text-field="name"
          disabled-field="notEnabled"
          ref="branch"
          @change="onBranchSelected(orderSelected.branchId)"
        >
          <template v-slot:first>
            <b-form-select-option value="null" disabled
              >กรุณาเลือกสาขา</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
    </div>
    <hr v-if="isShowBar" class="text-muted" />
    <div class="h-100">
      <div class="mt-2 mx-3 mb-3" v-if="step === 0">
        <div>
          <div class="bg-color-secondary rounded shadow-sm text-center p-5">
            <span class="text-color-secondary">ยังไม่มีการทำรายการ</span>
          </div>
        </div>
      </div>
      <div
        v-if="step === 1 || step === 2"
        :class="[
          'scroll-div-order-form',
          step === 1 && !isShowBar
            ? 'div-order-form-step-1-collapse'
            : step === 1
            ? 'div-order-form-step-1'
            : 'div-order-form'
        ]"
      >
        <div v-if="isOrderSendered" class="panel-status p-3 mt-2">
          <div class="d-flex">
            <div>
              <span class="">Order No. :</span>
              <span class="ml-2 text-c-red font-weight-bold">{{
                orderSelected.invoiceNo
              }}</span>
            </div>
            <div class="ml-auto">
              <span
                class="badge badge-pill font-weight-normal py-1 px-3"
                :style="styleCustom(orderSelected.statusId)"
                >{{ orderSelected.statusName }}</span
              >
            </div>
          </div>
          <div class="d-flex">
            <div>
              <span class="">Date:</span>
              <span class="ml-2" v-if="orderSelected.createdTime"
                >{{
                  new Date(orderSelected.createdTime) | moment($formatDate)
                }}
                ({{ orderSelected.createdTime | moment("HH:mm:ss") }})</span
              >
              <span v-else class="ml-2">-</span>
            </div>
            <div class="ml-auto">
              <span class="">Tracking No. :</span>
              <a
                :href="orderSelected.trackingNo.urlLink || '#'"
                target="_blank"
                class="ml-1"
                >{{ orderSelected.trackingNo.text || "-" }}</a
              >
            </div>
          </div>
        </div>
        <div class="d-flex mx-3">
          <div class="my-auto">
            <span class="font-weight-bold">รายการสินค้า</span>
          </div>
          <b-button
            v-if="orderSelected.statusId < 2"
            class="ml-auto"
            variant="link"
            @click="setShowModalProducts(true)"
            >เพิ่ม/แก้ไข</b-button
          >
        </div>
        <div>
          <div class="mt-2 mx-3 mb-3" v-if="orderSelected.products.length == 0">
            <div class="bg-color-secondary rounded shadow-sm text-center p-5">
              <span class="text-color-secondary">ไม่มีรายการ</span>
            </div>
          </div>
          <div
            class="my-2 mx-3"
            v-for="(item, index) in orderSelected.products"
            :key="index + 'product'"
          >
            <ChatOrderProductCard
              :orderStatus="orderSelected.statusId"
              :product="item"
              :branchId="orderSelected.branchId"
              :addProductQuantity="addProductQuantity"
              :removeProductQuantity="removeProductQuantity"
              isAction
            />
          </div>
        </div>
        <div>
          <div class="d-flex mt-3 mx-3">
            <div class="my-auto">
              <span class="font-weight-bold">รายการของแถม</span>
            </div>
          </div>
          <div
            class="mt-2 mx-3 mb-3"
            v-if="orderSelected.freeProducts.length == 0"
          >
            <div>
              <div class="bg-color-secondary rounded shadow-sm text-center p-5">
                <span class="text-color-secondary">ไม่มีรายการของแถม</span>
              </div>
            </div>
          </div>
          <div v-else class="mx-3 mb-4">
            <ChatOrderFreeProduct :freeProducts="freeProducts" />
          </div>
        </div>
        <ChatOrderSum
          :orderSelected="orderSelected"
          class="mb-2 pt-2 border-top"
        />
        <ChatOrderCoupon
          v-if="orderSelected.statusId < 2 || orderSelected.promoCode"
          :order="orderSelected"
          :isAction="orderSelected.statusId === 1"
          class="mb-3"
          :onCalculatedPrice="onCalculatedPrice"
          :isUseCoupon="isUseCoupon"
        />
        <ChatOrderNote
          :order="orderSelected"
          :isAction="orderSelected.statusId === 1"
          class="mb-4"
        />
        <div>
          <div class="">
            <div class="border-top pt-3">
              <span class="font-weight-bold px-3">ข้อมูลที่อยู่</span>
              <ChatOrderProfileAddressPanel
                ref="ChatOrderProfileAddressPanelOrder"
                :userDetail="userDetail"
                :userInfo="userInfo"
                :isOrderSendered="isOrderSendered"
                :orderSelected="orderSelected"
                :userDetailForCheckout="userDetailForCheckout"
              />
            </div>
            <div class="border-top pt-3 px-3 mb-4">
              <div class="d-flex">
                <span class="font-weight-bold my-auto">ข้อมูลการชำระเงิน</span>
                <b-button
                  v-if="orderSelected.statusId > 1"
                  variant="link"
                  class="ml-auto pr-0"
                  @click="setShowModalPayment(true)"
                >
                  รายละเอียด/แก้ไข
                </b-button>
              </div>
              <ChatOrderPaymentDetail
                :transaction="orderSelected"
                :setShowModalPayment="setShowModalPayment"
                class="mt-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="step === 1 || step === 2"
        class="w-100 pt-2 panel-btn-footer border-top py-2"
      >
        <div class="d-flex div-grantotal font-weight-bold px-3">
          <div><span class="">ราคาสุทธิ</span></div>
          <div class="ml-auto">
            <span class="">{{
              orderSelected.grandTotal | numeral("0,0.00")
            }}</span>
          </div>
        </div>
        <div
          v-if="step === 1 && !isOrderSendered"
          class="d-flex w-100 justify-content-end px-3 pt-2"
        >
          <b-button
            variant="danger"
            class="rounded btn-send-order px-4"
            @click="onSubmitedOrder(1)"
            :disabled="orderSelected.products.length == 0"
            >บันทึกออเดอร์</b-button
          >
        </div>
        <div
          v-if="isOrderSendered"
          :class="['d-flex w-100 px-3 pt-2', 'justify-content-between']"
        >
          <b-button
            variant="danger"
            class="rounded px-4 btn-no-send-order"
            @click="$emit('set-select-transaction-id', null)"
            >กลับ</b-button
          >
          <b-button
            v-if="orderSelected.statusId === 1"
            variant="danger"
            class="rounded btn-send-order px-3"
            @click="onSubmitedOrder(1)"
            >บันทึกออเดอร์</b-button
          >
        </div>
      </div>
    </div>
    <ChatOrderModalAddProduct
      v-if="isShowModalProduct"
      v-bind:isShow="isShowModalProduct"
      @setShowModalProducts="setShowModalProducts"
      :brandSelected="orderSelected.brandId"
      :branchSelected="orderSelected.branchId"
      :addProductToLists="addProductToLists"
    />
    <ModalPaymentDetail
      v-if="orderSelected.orderId"
      ref="modalPaymentDetail"
      :orderId="orderSelected.orderId"
      :transactionId="orderSelected.id"
      :invoiceNo="orderSelected.invoiceNo"
      :orderTotalAmount="orderSelected.grandTotal"
      :orderStatus="orderSelected.statusId"
      v-on:onRefreshData="() => {}"
    />
  </div> -->
</template>

<script>
import RecentActivity from "@/components/chat/chatOrder/chatOrderList/activity/RecentActivity.vue";
import NoteDetail from "@/components/chat/chatOrder/chatOrderList/note/NoteDetail.vue";
// import ChatOrderModalAddProduct from "@/components/chat/chatOrder/chatOrderList/ChatOrderModalAddProduct";
// import ChatOrderProductCard from "@/components/chat/chatOrder/ChatOrderProductCard.vue";
// import ChatOrderSum from "@/components/chat/chatOrder/chatOrderList/ChatOrderSum";
// import ChatOrderFreeProduct from "@/components/chat/chatOrder/chatOrderList/ChatOrderFreeProduct";
// import ChatOrderNote from "@/components/chat/chatOrder/chatOrderList/ChatOrderNote";
// import ChatOrderCoupon from "@/components/chat/chatOrder/chatOrderList/ChatOrderCoupon";
// import ChatOrderProfileAddressPanel from "@/components/chat/chatOrder/chatOrderProfile/chatOrderProfileAddress/ChatOrderProfileAddressPanel";
// import ChatOrderProfileInfo from "@/components/chat/chatOrder/chatOrderProfile/ChatOrderProfileInfo";
// import ChatOrderPaymentDetail from "@/components/chat/chatOrder/chatOrderList/ChatOrderPaymentDetail";
// import ModalPaymentForm from "@/components/modal/ModalPaymentForm";
// import ModalPaymentDetail from "@/components/order/modal/ModalPaymentDetail";
import * as moment from "moment/moment";
import { mapGetters } from "vuex";

export default {
  components: {
    RecentActivity,
    NoteDetail
    // ChatOrderModalAddProduct,
    // ChatOrderProductCard,
    // ChatOrderSum,
    // ChatOrderFreeProduct,
    // ChatOrderNote,
    // ChatOrderCoupon,
    // ChatOrderProfileAddressPanel
    // ChatOrderPaymentDetail,
    // ChatOrderProfileInfo,
    // ModalPaymentForm,
    // ModalPaymentDetail
  },
  name: "ChatOrderList",
  props: {
    isOrderSendered: {
      required: false,
      type: Boolean
    },
    selectTransactionId: {
      required: false,
      type: Number
    },
    userId: {
      required: false,
      type: String
    },
    socialSourceId: {
      required: false,
      type: String
    },
    userInfo: {
      required: false,
      type: Object
    },
    userDetail: {
      required: false,
      type: Object
    },
    orderSelected: {
      required: false,
      type: Object
    },
    handleFinishCreateOrder: {
      required: false,
      type: Function
    },
    handleShowModalCopy: {
      required: false,
      type: Function
    },
    onsetOrderSelected: {
      required: false,
      type: Function
    },
    getOrderDetail: {
      required: false,
      type: Function
    },
    handleFilterClickSearch: {
      required: false,
      type: Function
    },
    chatRoomId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      step: 0,
      isShowModalProduct: false,
      // isShowModalPayment: false,
      defaultVal: null,
      forceRefresh: true,
      saleChannelLists: [],
      branchLists: [],
      freeProducts: [],
      message: "",
      changeStatusFlag: false,
      isShowModalCancel: false,
      isShowModalTracking: false,
      userDetailForCheckout: {},
      scrollToBottomFlag: false,
      isUseCoupon: false,
      isFlagSendOrder: false,
      isShowBar: true
    };
  },
  computed: {
    ...mapGetters({
      profileNote: "getAgentNote"
    })
  },
  methods: {
    onBranchSelected(branchId) {
      this.orderSelected.branchId = branchId;
      // this.orderSelected.products = [];
    },
    checkInfo(val) {
      if (val && val != "") {
        return val;
      } else return "-";
    },
    getAllBranch() {
      this.$axios
        .get(
          `${this.$baseUrlOrder}/api/brand/${this.orderSelected.brandId}/branch`
        )
        .then(response => {
          if (response.data.result === 1) {
            this.branchLists = response.data.detail;

            if (this.branchLists.length === 1) {
              this.orderSelected.branchId = this.branchLists[0].id;
            } else {
              if (!this.isOrderSendered) {
                this.orderSelected.branchId = null;
              }
            }
          }
        });
    },
    handleNextStep: async function() {
      await this.getBuyerInfoBySocialReferenceId();
      this.step = 2;
    },
    addProductToLists(product) {
      let filterProduct = this.orderSelected.products.some(
        item => item.id === product.id
      );
      if (filterProduct) {
        let indexTarget = this.orderSelected.products.findIndex(
          item => item.id === product.id
        );
        if (indexTarget > -1) {
          product.quantity =
            this.orderSelected.products[indexTarget].quantity + 1;
          this.orderSelected.products[indexTarget] = product;
        }
      } else {
        product.quantity = 1;
        this.orderSelected.products.push(product);
      }
      this.orderSelected.productDiscount.forEach(element => {
        element.products.forEach(pd => {
          if (pd.productId == product.id) {
            pd.selected = true;
          }
        });
      });
      this.onCalculatedPrice();
    },
    addProductQuantity(product) {
      if (this.orderSelected.statusId === 1) {
        let indexTarget = this.orderSelected.products.findIndex(
          item => item.id === product.id
        );
        if (indexTarget > -1) {
          product.quantity =
            this.orderSelected.products[indexTarget].quantity + 1;
          this.orderSelected.products[indexTarget] = product;
        }
        if (this.isUseCoupon == false) {
          this.orderSelected.promoCode = "";
        }
        this.onCalculatedPrice();
      }
    },
    removeProductQuantity(product) {
      if (this.orderSelected.statusId === 1) {
        let indexTarget = this.orderSelected.products.findIndex(
          item => item.id === product.id
        );
        if (indexTarget > -1) {
          product.quantity =
            this.orderSelected.products[indexTarget].quantity - 1;
          this.orderSelected.products[indexTarget] = product;
        }
        this.onCalculatedPrice();
      }
    },
    onCalculatedPrice() {
      let body = {
        brandId: this.orderSelected.brandId,
        branchId: this.orderSelected.branchId,
        products: this.orderSelected.products,
        freeProducts: this.orderSelected.freeProducts,
        productDiscount: this.orderSelected.productDiscount,
        promoCode: this.orderSelected.promoCode
      };
      this.$axios
        .post(this.$baseUrl + "/api/transaction/calculate", body)
        .then(response => {
          this.orderSelected.products = response.data.detail.products;
          this.orderSelected.grandTotal = response.data.detail.grandTotal;
          this.orderSelected.shippingCost = response.data.detail.shippingCost;
          this.orderSelected.discount = response.data.detail.discount;
          this.orderSelected.promotions = response.data.detail.promotions;
          this.orderSelected.total = response.data.detail.total;
          this.orderSelected.freeProducts = response.data.detail.freeProducts;
          this.orderSelected.productDiscount =
            response.data.detail.productDiscount;
          this.orderSelected.promoCode = response.data.detail.promoCode;
          this.orderSelected.promoCodeMessage =
            response.data.detail.promoCodeMessage;
          this.getFreeProducts();
          if (this.orderSelected.promoCode) {
            this.isUseCoupon = true;
          } else {
            this.isUseCoupon = false;
          }
        });
    },
    getFreeProducts() {
      let promotions = [];
      let freeProducts = JSON.parse(
        JSON.stringify(this.orderSelected.freeProducts)
      );

      freeProducts.forEach(element => {
        let promotionIndex = promotions.findIndex(el => {
          return (
            el.promotionId == element.promotionId &&
            el.promotionIndex == element.promotionIndex
          );
        });
        if (promotionIndex == -1) {
          promotions.push({
            promotionId: element.promotionId,
            promotionName: element.promotionName,
            products: element.products
          });
        } else {
          element.products.forEach(product => {
            let productIndex = promotions[promotionIndex].products.findIndex(
              el => {
                return el.productId == product.productId;
              }
            );
            if (productIndex == -1) {
              promotions[promotionIndex].products.push(product);
            } else {
              promotions[promotionIndex].products[productIndex].quantity +=
                product.quantity;
            }
          });
        }
      });

      this.freeProducts = promotions;
    },
    onSubmitedOrder: async function(flag) {
      this.isFlagSendOrder = false;
      if (!this.isOrderSendered) {
        let adr = await this.$refs.ChatOrderProfileAddressPanelOrder.getOrderAddress();
        if (!adr.isHaveAddress) return;
        // this.orderSelected.buyerInfo.userInfo = this.userDetailForCheckout.userInfo;
        this.orderSelected.buyerInfo.shippingAddress = adr.shippingAddress;
        this.orderSelected.buyerInfo.billingAddress = adr.billingAddress;
        this.orderSelected.buyerInfo.sameAddress = adr.sameAddress;
        this.orderSelected.buyerInfo.isRequestTax = adr.isRequestTax;
      }
      this.isFlagSendOrder = flag == 2 ? true : false;
      this.onCreateOrUpdateOrder();

      // if (this.orderSelected.saleChannel !== null) {
      //   if (this.orderSelected.statusId == 7 && this.changeStatusFlag) {
      //     this.setShowModalCancelOrder(true);
      //   } else if (this.changeStatusFlag) {
      //     this.$store.dispatch("setIsLoading", true);
      //     this.onChangeStatus(
      //       this.orderSelected.statusId,
      //       false,
      //       this.isRestock
      //     );
      //   } else if (!this.changeStatusFlag) {
      //     if (this.orderSelected.statusId !== 1) {
      //       this.$store.dispatch("setIsLoading", true);
      //       this.onGetUrlLink(this.orderSelected.id);
      //     } else {
      //       this.$store.dispatch("setIsLoading", true);
      //       this.onCreateOrUpdateOrder();
      //     }
      //   }
      // } else {
      //   this.$refs.saleChannel.focus();
      // }
    },
    onCreateOrUpdateOrder() {
      this.$store.dispatch("setIsLoading", true);
      let typeId = this.userInfo.socialSourceId;
      // if ([1, 2].includes(this.orderSelected.saleChannel)) {
      //   typeId = 2;
      // } else if (this.orderSelected.saleChannel == 3) {
      //   typeId = 1;
      // }
      if (!typeId) {
        this.$store.commit(
          "setMessageGlobalAlertError",
          "ไม่พบ Social Sales Channel กรุณาตรวจสอบอินเตอร์เน็ตหรือรีเฟรชหน้าเว็บอีกครั้ง"
        );
        this.$store.commit("setShowGlobalAlertError", true);
      } else {
        this.$axios
          .post(this.$baseUrl + "/api/transaction/save", {
            cart: {
              brandId: this.orderSelected.brandId,
              branchId: this.orderSelected.branchId,
              id: this.orderSelected.id,
              products: this.orderSelected.products,
              typeId: typeId,
              referenceId: this.userInfo.socialReferenceId,
              isSeller: true,
              socialSalesChannel: this.orderSelected.saleChannel,
              freeProducts: this.orderSelected.freeProducts,
              productDiscount: this.orderSelected.productDiscount,
              sessionId: this.userId,
              isNotifyToUser: false,
              promoCode: this.orderSelected.promoCode
            },
            note: this.orderSelected.note
          })
          .then(response => {
            this.$store.dispatch("setIsLoading", false);
            if (response.data.result === 1) {
              if (response.data.detail.message) {
                this.$store.commit(
                  "setMessageGlobalAlertSecondLine",
                  `หมายเหตุ: ${response.data.detail.message}`
                );
              } else {
                this.handleShowModalCopy(response.data.detail);
              }
              if (!this.isOrderSendered) {
                this.sendUpdatedAddress(
                  this.orderSelected.buyerInfo,
                  response.data.detail.id
                );
              } else {
                // if (this.isFlagSendOrder) {
                // this.pushOrder(response.data.detail.id); // ส่ง order ไปแชท
                // } else {
                // this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
                // this.$store.commit("setShowGlobalAlert", true);
                // }
                this.handleFinishCreateOrder();
              }
            } else {
              this.$store.commit(
                "setMessageGlobalAlertError",
                response.data.message
              );
              this.$store.commit("setShowGlobalAlertError", true);
            }
          });
      }
    },
    sendUpdatedAddress: async function(value, orderId) {
      let valBody = { ...value, isNotifyToUser: false };
      await this.$axios
        .post(
          this.$baseUrl + "/api/transaction/" + orderId + "/buyerinfo",
          valBody
        )
        .then(response => {
          if (response.data.result === 1) {
            // if (this.isFlagSendOrder) {
            // this.pushOrder(orderId); // ส่ง ออร์เดอร์ไปแชท
            // } else {
            //   this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            //   this.$store.commit("setShowGlobalAlert", true);
            //   // this.getOrderInformationById(orderId);
            // }
            this.handleFinishCreateOrder(response.data.detail);
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.message || response.data.detail || ""
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        });
    },
    setShowModalProducts(value) {
      if (this.orderSelected.branchId) {
        this.isShowModalProduct = value;
      } else {
        this.$refs.branch.focus();
      }
    },
    getBuyerInfoBySocialReferenceId: async function() {
      this.$store.dispatch("setIsLoading", true);
      await this.$axios
        .get(
          this.$baseUrl + "/api/user/address/" + this.userInfo.socialReferenceId
        )
        .then(response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.result == 1) {
            // console.log(response.data);
            this.userDetailForCheckout = response.data.detail;
          }
        });
    },
    moment: function() {
      return moment();
    },
    onUpdatedPayment(
      tranferObj,
      imageList,
      imageIdLists,
      approveFlag,
      orderSelectedNote,
      note,
      reasonId
    ) {
      this.$store.dispatch("setIsLoading", true);
      this.imageIdLists = imageIdLists;
      var transferDate = moment(tranferObj.transferDate).format("MM/DD/YYYY");
      var transferTime = moment(new Date(tranferObj.transferTime)).format(
        "HH:mm"
      );
      if (approveFlag === true) {
        this.$axios
          .post(this.$baseUrl + "/api/payment/updateSlipInfo", {
            Amount: tranferObj.amount,
            transactionid: this.orderSelected.id,
            transferDate: transferDate,
            transferTime: transferTime,
            bankAccountId: tranferObj.bankAccountId,
            UploadImage: imageList,
            deleteImage: this.imageIdLists
          })
          .then(response => {
            if (response.data.result === 1) {
              this.setShowModalPayment(false);
              this.onChangeStatus(4, true, false);
              if (note && note !== "") {
                this.onSaveNote(orderSelectedNote);
              }
            }
          });
      } else {
        this.onChangeStatus(8, true, false, reasonId, note);
        this.setShowModalPayment(false);
      }
    },
    onSaveNote(note) {
      this.$axios
        .post(this.$baseUrl + "/api/transaction/note", {
          OrderId: this.orderSelected.id,
          Note: note
        })
        .then(response => {
          if (response.data.result !== 1) {
            alert(response.data.message);
          }
        });
    },
    onChangeStatus: async function(id, isShowModal, isRestock, reasonId, note) {
      let body = {};
      if (note) {
        body = {
          orderId: this.orderSelected.id,
          statusId: id,
          reStock: isRestock,
          reasonId: reasonId,
          reasonNote: note
        };
      } else {
        body = {
          orderId: this.orderSelected.id,
          statusId: id,
          reStock: isRestock
        };
      }
      await this.$axios
        .post(this.$baseUrl + "/api/transaction/authorizedstatus", body)
        .then(response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.result === 1) {
            if (isShowModal === true) {
              if (id === 4) {
                this.$store.commit(
                  "setMessageGlobalAlert",
                  "ยืนยันการชำระเงินสำเร็จ"
                );
                this.$store.commit("setShowGlobalAlert", true);
              } else if (id === 8) {
                this.$store.commit(
                  "setMessageGlobalAlert",
                  "ปฏิเสธการยืนยันการชำระเงินสำเร็จ"
                );
                this.$store.commit("setShowGlobalAlert", true);
              }
            }

            // this.getOrderInformationById(this.orderSelected.id);
            // this.$store.dispatch("setReloadListFlag", true);
            this.getOrderDetail(this.orderSelected.id);
            this.handleFilterClickSearch();
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        });
    },
    setShowModalPayment(value) {
      if (value) {
        this.$refs.modalPaymentDetail.show();
      } else {
        this.$refs.modalPaymentDetail.hide();
      }
      // this.isShowModalPayment = value;
      // if (!this.isShowModalPayment) {
      //   this.handleForcrrefresh();
      // }
    },
    handleForcrrefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    pushOrder(transac) {
      this.$axios
        .post(this.$baseUrl + `/api/transaction/${transac}/pushOrder`, {
          id: transac
        })
        .then(response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.result === 1) {
            this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    styleCustom(stsId) {
      if (stsId == 5) {
        return {
          backgroundColor: "#d4edda",
          color: "#155724"
        };
      } else if ([6, 7, 8, 12].includes(stsId)) {
        return {
          backgroundColor: "#f8d7da",
          color: "#721c24"
        };
      } else if ([1].includes(stsId)) {
        return {
          backgroundColor: "#e2e3e5",
          color: "#383d41"
        };
      } else if ([4, 14, 15].includes(stsId)) {
        return {
          backgroundColor: "rgb(113 96 196 / 27%)",
          color: "#7160C4"
        };
      } else {
        return {
          backgroundColor: "#fff3cd",
          color: "#856404"
        };
      }
    },
    handleClickCreateNew() {
      this.step = 1;
      this.$emit("set-address-from-list");
    },
    copyClipboard(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {})
        .catch(err => {
          console.error("Error in copying text: ", err);
        });
    },
    goToUrl(url) {
      window.open(`${url}`, "_blank").focus();
    }
  },
  // mounted() {
  //   console.log(this.userDetail, "userDetail");
  // },
  created: async function() {
    if (this.isOrderSendered) {
      this.step = 2;
      this.getFreeProducts();
    } else {
      this.onsetOrderSelected();
    }
    // this.getAllBranch();
  },
  updated() {
    // if (this.$route.query.isMobile == "true") {
    //   let tempObjDiv = document.querySelector(".scroll-div-order-form");
    //   if (tempObjDiv != null) {
    //     tempObjDiv.addEventListener("scroll", () => {
    //       if (this.step > 0) {
    //         if (tempObjDiv.scrollTop == 0) {
    //           this.isShowBar = true;
    //         } else {
    //           this.isShowBar = false;
    //         }
    //       }
    //     });
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.closed {
  color: #9450c2;
}

.icon {
  color: #9450c2;
  cursor: pointer;
}

.not-collapsed > .closed {
  transform: rotate(180deg);
  transition: 0.2s all;
}

.collapsed > .closed {
  transform: rotate(0deg);
  transition: 0.2s all;
}

.div-order-form {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 470px);
}

.div-order-form-step-1 {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 465px) !important;
}

.text-secondary {
  color: #9c9c9c !important;
}

.panel-btn-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.image {
  width: 20px;
}

.btn-add-product,
.btn-next,
.btn-send-order {
  background-color: #832abf !important;
  border-color: #832abf;
}

.btn-no-send-order {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #9450c2;
}

.tags {
  color: #fff;
  background: #1fdea2;
  padding: 0px 10px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-no-product-yet {
  font-size: 18px;
  color: #9450c2;
}

.div-add-pro {
  height: 5rem;
}

.btn-create-order {
  background-color: #832abf !important;
  color: #fff !important;
}

.title {
  font-size: 18px;
}

.list {
  min-width: 130px;
}

.section {
  .list {
    .title {
      color: #9c9c9c;
      white-space: nowrap;
      margin: 4px 0px;
      font-size: 14px;
      .color-no-edit {
        color: #2c3e50;
      }
    }
  }
}

.detail span {
  margin: 4px 0px;
}

.border-title {
  // border-left: 3px solid #9450c2;
  height: 2.3rem;
  width: 5px;
  background-color: #832abf !important;
}

.div-grantotal {
  font-size: 18px;
}

hr {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.section {
  overflow: auto;
  width: 100%;
  height: calc(100dvh - 152px);
}

.badge-badge-private {
  color: #fff;
  background-color: #832abf;
  border: 1px solid #832abf;
}
.badge-badge-public {
  background-color: #fff;
  color: #832abf;
  border: 1px solid #832abf;
  font-size: 10px;
}
.customer-detail div div:first-child {
  white-space: nowrap;
  color: #9f9f9f;
}
.edit-icon,
.icon-bd {
  color: #832abf;
  cursor: pointer;
  font-size: 16px;
}
.last-update-note {
  font-size: 12px;
  span {
    color: #bfbfbf;
  }
  .admin-name {
    color: #2c3e50;
    font-weight: 600;
  }
  .note {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767.98px) {
  .div-order-form {
    height: calc(100% - 410px);
  }
  .div-order-form-step-1 {
    height: calc(100% - 400px) !important;
  }

  .div-order-form-step-1-collapse,
  .div-order-form {
    height: calc(100% - 280px) !important;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
  }
}
</style>
