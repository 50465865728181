<template>
  <div>
    <!-- 2,3,5 TextButton,TextImage,Flex -->
    <div
      v-if="
        con.messageType == 2 || con.messageType == 3 || con.messageType == 5
      "
    >
      <ChatTextButtonImage :con="con" />
    </div>

    <!-- 6,7 FlexCarousel,ImageCarouse -->
    <div v-if="con.messageType == 6 || con.messageType == 7">
      <ChatCarousel :con="con" />
    </div>

    <!-- 8 File -->
    <div v-if="con.messageType == 8">
      <font-awesome-icon icon="file-download" class="" />
      <span class="msg-file" @click="handleOpenTab(con.filePath)">
        {{ con.message }}
      </span>
    </div>
    <div v-if="con.messageType == 9">
      <font-awesome-icon icon="file-download" class="" />
      <span class="msg-file" @click="handleOpenTab(con.message)">
        {{ con.message }}
      </span>
    </div>
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ChatTextButtonImage from "../chatIntent/ChatTextButtonImage.vue";
import ChatCarousel from "../chatIntent/ChatCarousel.vue";

export default {
  name: "ChatBoxWidget",
  components: {
    // VueSlickCarousel
    ChatTextButtonImage,
    ChatCarousel
  },
  props: {
    con: {
      required: false,
      type: Object
    }
  },
  methods: {
    handleOpenTab(url) {
      window.open(url, "_blank");
    }
  },
  data() {
    return {
      contentFile: {
        MessageId: "",
        UserGUID: "da9d7c15-7538-4e8c-a093-074135a9c7cb",
        BrandId: 1,
        PlatformId: 2,
        AdminMode: false,
        CreatedTime: "2021-07-16T14:14:43.339445+07:00",
        Message: {
          ReplyType: 8,
          FileUrl: `${this.$chatbotUrl}/files/210009060015882.pdf`,
          Name: "210009060015882.pdf",
          LanguageId: 1
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.msg_wrap {
  white-space: pre-line;
  overflow-wrap: break-word;
}
.wrap-product-img {
  width: 100%;
  height: auto;
}
.product-img {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.btn-card {
  color: #fff;
}
.msg-card-title {
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 3em;
  margin-bottom: 0px;
  line-height: normal;
}
.msg-card-sub-title {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 3em;
  margin-bottom: 0px;
  line-height: normal;
}
@media (max-width: 767.98px) {
}
.msg-file {
  text-decoration: underline;
  cursor: pointer;
}
</style>
