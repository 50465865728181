<template>
  <b-modal
    v-model="isModalShow"
    title="Connect Customer Data"
    hide-footer
    @hide="hide"
    size="lg"
  >
    <div>
      <InputText
        v-model="filter.searchDefault"
        textFloat="ค้นหาข้อมูลลูกค้า"
        placeholder="Search Name, Telephone, Member ID"
        isRequired
        type="text"
        icon="search"
        @submit="onSearchCustomer"
        @onkeyup="handleKeyup"
      />
      <!-- <div class="text-center">
          <b-button variant="main-primary" @click="onSearchCustomer">
            Comfirm
          </b-button>
        </div> -->
      <div class="panel-table">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="filter.perPage"
          :busy="isBusy"
          show-empty
          empty-text="Empty Data"
          responsive
          class="table-main"
        >
          <template v-slot:cell(ids)="data">
            <b-button
              variant="main-primary"
              @click="onSelectCustomer(data.item)"
            >
              Select
            </b-button>
          </template>
          <template v-slot:cell(name)="data">
            <div class="d-flex flex-column align-items-start">
              <span>{{ data.item.name }}</span>
              <span class="member_id">{{ data.item.member_id }}</span>
            </div>
          </template>
          <template v-slot:cell(telephone)="data">
            <div class="text-center">
              <span>{{ data.item.telephone }}</span>
            </div>
          </template>
          <template v-slot:cell(created_time)="data">
            <div class="text-center">
              <span v-if="data.item.created_time">{{
                data.item.created_time
              }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-row class="no-gutters">
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-md-start"
          >
            <p class="my-2 my-md-0">
              Showing {{ showing === 1 ? showing : showing + 1 }} to
              {{ showingTo }} of {{ rows | numeral("0,0") }} Entries
            </p>
          </b-col>
          <b-col md="6" class="d-md-flex text-center">
            <b-pagination
              v-model="filter.pageNo"
              :total-rows="rows"
              :per-page="filter.perPage"
              class="pagination-main mb-2 mb-md-0"
              @change="handlePagination"
              align="center"
            ></b-pagination>
            <b-form-select
              class="ml-md-3 w-50"
              v-model="filter.perPage"
              @change="handleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalLoading ref="modalLoading" />
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "../modal/ModalLoading";

import { mapGetters } from "vuex";

export default {
  name: "ChatBoxModalAssignTo",
  props: {
    userChatInfo: {
      required: false,
      type: Object
    }
  },
  components: {
    InputText,
    ModalLoading
  },
  data() {
    return {
      filter: {
        searchDefault: "",
        pageNo: 1,
        perPage: 10,
        brandId: 0,
        userGUID: ""
      },
      isBusy: false,
      loadFlag: false,
      isModalShow: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      items: [],
      fields: [
        {
          key: "ids",
          label: "",
          class: "col-ids"
        },
        {
          key: "name",
          label: "Name / Member ID"
        },
        {
          key: "telephone",
          label: "Telephone",
          thClass: "text-center"
        },
        {
          key: "created_time",
          label: "Register Date",
          thClass: "text-center"
        }
      ],
      pageOptions: [
        { value: 10, text: `10 / Page` },
        { value: 30, text: `30 / Page` },
        { value: 50, text: `50 / Page` },
        { value: 100, text: `100 / Page` }
      ],
      timer: null
    };
  },
  computed: {
    ...mapGetters({
      brandId: "getBrand"
    })
  },
  methods: {
    hide() {
      this.isModalShow = false;
    },
    show() {
      this.isModalShow = true;
      this.clearItems();
      this.getCustomer();
    },
    clearItems() {
      this.filter.searchDefault = "";
      this.filter.pageNo = 1;
      this.filter.perPage = 10;
      this.showing = 1;
      this.showingTo = 10;
      this.rows = 0;
      this.items = [];
    },
    handlePagination(pageNo) {
      this.filter.pageNo = pageNo;
      if (pageNo === 1) {
        this.showing = this.filter.pageNo;
        this.showingTo = this.filter.perPage;
      } else {
        this.showing = pageNo * this.filter.perPage - this.filter.perPage;
        this.showingTo = this.showing + this.filter.perPage;
      }
      this.getCustomer();
    },
    handleChangePerpage(perPage) {
      this.filter.pageNo = 1;
      this.filter.perPage = perPage;
      this.showing = this.filter.pageNo;
      this.showingTo = this.filter.perPage;
      this.getCustomer();
    },
    onSearchCustomer() {
      this.getCustomer();
    },
    getCustomer: async function() {
      this.isBusy = true;

      this.filter.brandId = this.brandId;
      this.filter.userGUID = this.$store.state.chatAdminProfile.externalId;
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/crm/customer`,
        this.filter
      );
      if (response.data.result) {
        this.items = response.data.detail.data;
        this.rows = response.data.detail.total;
      }

      this.isBusy = false;
    },
    async onSelectCustomer(item) {
      this.$refs.modalLoading.show();
      this.isShowModalOverLay = true;
      let body = {
        userID: this.userChatInfo.externalId,
        crmUserId: item.user_guid
      };
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/crm/connect-customer`,
        body
      );
      setTimeout(() => {
        this.$refs.modalLoading.hide();
      }, 1000);

      if (response.data.result) {
        this.$emit("selectedCustomer", item);
        this.$store.commit("setMessageGlobalAlert", "Connect Success");
        this.$store.commit("setShowGlobalAlert", true);
        this.hide();
      } else {
        this.$store.commit("setMessageGlobalAlertError", response.data.message);
        this.$store.commit("setShowGlobalAlertError", true);
      }
    },
    handleKeyup() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onSearchCustomer();
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.member_id {
  font-size: 12px;
  color: #898888;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
::v-deep .col-ids {
  width: 105px !important;
  max-width: 105px !important;
  button {
    min-width: 80px;
  }
}
</style>
