<template>
  <b-modal
    v-model="isShowModalOverLay"
    no-fade
    hide-footer
    hide-header
    centered
    size="sm"
  >
    <div class="text-center">
      <img
        src="@/assets/images/loading.svg"
        alt="loading"
        class="my-2 loading-icon"
      />
      <div class="textc-primary">กรุณารอสักครู่</div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isShowModalOverLay: false
    };
  },
  methods: {
    show() {
      this.isShowModalOverLay = true;
    },
    hide() {
      this.isShowModalOverLay = false;
    }
  }
};
</script>

<style scoped>
::v-deep #tags {
  margin-left: 5px !important;
}

::v-deep .h-auto {
  height: 100px !important;
  overflow: auto;
}

::v-deep .text-truncate {
  margin-right: 5px;
  margin-left: 2px;
  font-size: 12px !important;
}

::v-deep .align-items-baseline {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: rgba(113, 96, 196, 0.27);
  border-radius: 2px;
  color: rgb(113, 96, 196);
  cursor: pointer;
}
</style>
