<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="confirmCancelModal"
      hide-header
      hide-footer
      centered
    >
      <div class="modal-header">
        <h5 class="f-md-16">{{ msg }}</h5>
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div class="text-center px-md-5 py-4">
        <p class="text-text f-md-14">{{ text }}</p>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <div class="pr-2">
          <b-button
            type="button"
            class="btn-mobile-md f-size-16"
            variant="outline-primary"
            @click="hide"
            >{{ btnCancel }}</b-button
          >
        </div>
        <div class="pl-2">
          <b-button
            type="button"
            class="btn-mobile-md f-size-16"
            variant="primary"
            @click="$emit('confirm')"
            >{{ btnConfirm }}</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    },
    btnConfirm: {
      required: true,
      type: String
    },
    btnCancel: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  margin-bottom: 0;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.btn-primary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.btn-outline-primary {
  border: 1px solid #9450c2;
  color: #832abf;
}
</style>
