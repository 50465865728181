var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.content.custom.imageUrl)?_c('div',{staticClass:"img_upload"},[_c('div',{staticClass:"img_upload-display",style:({
        backgroundImage: 'url(' + _vm.content.custom.imageUrl + ')'
      })})]):_vm._e(),(_vm.content.custom)?_c('div',[(_vm.content.messageType == 3)?_c('hr'):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.content.custom.text))]),(
        _vm.content.custom.button &&
          _vm.content.custom.imageUrl &&
          _vm.content.custom.text
      )?_c('hr'):_vm._e(),_vm._l((_vm.content.custom.button),function(btn,j){return _c('button',{key:j,staticClass:"btn btn-light w-100 mb-1 font-btn",attrs:{"type":"button"}},[_c('span',{staticClass:"font-btn"},[_vm._v(_vm._s(btn.label))])])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }