<template>
  <div class="dd bg-white">
    <div class="px-3 py-2 d-flex justify-content-between align-items-center">
      <span class="font-weight-bold">Note</span>
      <b-button-group class="btn-groups">
        <b-button
          variant="light"
          :class="[{ active: form.type == 1 }]"
          @click="handleType(1)"
        >
          Public
        </b-button>
        <b-button
          variant="light"
          :class="[{ active: form.type == 2 }]"
          @click="handleType(2)"
        >
          Private
        </b-button>
      </b-button-group>
    </div>
    <div>
      <b-form-textarea
        :id="`textarea-chatbox-note-${form.type}`"
        v-model="form.note"
        placeholder="กรอกบันทึก"
        rows="4"
        class="px-3 rounded-0 border-left-0 border-right-0"
      ></b-form-textarea>
    </div>
    <div class="d-flex align-items-center py-2 px-3">
      <div class="last-update-note" v-if="form.adminName">
        <span class="font-italic">Last Updated:</span>
        <span><ChatDateTime :date="form.updateTime"/></span>
        <span class="mr-1">by</span>
        <span class="admin-name">{{ form.adminName }}</span>
      </div>
      <b-button
        variant="outline-primary"
        size="sm"
        class="ml-auto"
        @click="cancel(false)"
        >ยกเลิก</b-button
      >
      <b-button
        variant="primary"
        size="sm"
        class="ml-2"
        @click="saveNote"
        :disabled="$store.state.isLoading"
        >บันทึก</b-button
      >
    </div>
    <ModalAlertConfirm
      msg="Do you want to exit the note without saving?"
      :text="`Note ${form.type == 1 ? 'Public' : 'Private'}: ${form.note}`"
      btnCancel="Back"
      btnConfirm="Confirm"
      ref="isModalAlertConfirm"
      @confirm="confirmWithoutSaving"
    />
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { mapGetters } from "vuex";

export default {
  name: "ChatBoxNote",
  components: {
    ChatDateTime,
    ModalAlertConfirm
  },
  props: {
    userGUID: {
      required: false,
      type: String
    },
    chatroomId: {
      required: false,
      type: String
    },
    handleLoadError: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      form: {
        chatroomId: null,
        createTime: "0001-01-01T00:00:00",
        note: null,
        id: null,
        type: 1,
        updateTime: "0001-01-01T00:00:00",
        updateUser: null,
        userGUID: null
      },
      noteText: ""
    };
  },
  computed: {
    ...mapGetters({
      note: "getAgentNote"
    })
  },
  methods: {
    async getNote(type) {
      await this.$store.dispatch("getAgentNoteType", {
        chatroomId: this.chatroomId,
        type: type
      });
      if (type == 1) this.form = { ...this.note.public };
      else if (type == 2) this.form = { ...this.note.private };

      this.noteText = this.form.note;
    },
    saveNote() {
      this.$store.dispatch("setIsLoading", true);
      let body = {
        userGUID: this.userGUID,
        chatroomId: this.chatroomId,
        note: this.form.note,
        type: this.form.type
      };
      fetch(`${this.$chatBaseUrl}/note/save`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          this.$store.dispatch("setIsLoading", false);
          if (result.result == 1) {
            this.getNote(this.form.type);
            this.$store.commit("setMessageGlobalAlert", "บันทึกสำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            this.$emit("handle-show-note", false);
          } else {
            this.handleLoadError();
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.result.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.handleLoadError();
          console.log("error", error);
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    handleType(type) {
      this.form.type = type;
      this.getNote(type);
    },
    cancel(val) {
      if (this.noteText != this.form.note) {
        this.$refs.isModalAlertConfirm.show();
      } else {
        this.$emit("handle-show-note", val);
      }
    },
    confirmWithoutSaving() {
      this.$emit("handle-show-note", false);
    }
  },
  created() {
    let type = this.note.public.note ? 1 : this.note.private.note ? 2 : 1;
    this.handleType(type);
  }
};
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.btn-outline-primary {
  border: 1px solid #9450c2;
  color: #832abf;
}
.btn-outline-primary:active,
.btn-outline-primary:hover {
  border: 1px solid #9450c2;
  color: #832abf;
  background-color: rgb(240, 240, 240) !important;
}
.btn-groups {
  button {
    background-color: #fff;
    color: #878787;
    border-color: #dbdbdb;
    font-size: 12px;
    padding: 2px 6px;
  }
  button.active {
    border-color: rgb(113, 96, 196);
    background-color: rgb(113, 96, 196);
    color: #fff;
  }
  button:hover {
    border-color: #878787;
  }
}
.last-update-note {
  color: #bfbfbf;
  font-size: 12px;
  .admin-name {
    color: #2c3e50;
    font-weight: 600;
  }
}
#textarea-chatbox-note {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
#textarea-chatbox-note:focus {
  box-shadow: none;
  border: 1px solid #958bef;
}
@media (max-width: 767.98px) {
}
</style>
