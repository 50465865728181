var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.urlPath)?_c('b-row',{staticClass:"mt-1 mb-3 mb-md-0"},[_c('b-col',{staticClass:"pr-0",attrs:{"cols":""}},[_c('b-aspect',{attrs:{"aspect":_vm.aspect}},[(['jpg', 'jpeg', 'png'].indexOf(_vm.fileTypeDisplay) >= 0)?_c('div',{class:['preview-file'],style:({
            'background-image': 'url(' + _vm.urlPath + ')'
          })}):(['pdf'].indexOf(_vm.fileTypeDisplay) >= 0)?_c('embed',{attrs:{"src":_vm.urlPath,"type":"application/pdf","frameBorder":"0","scrolling":"auto","height":"100%","width":"100%","allowfullscreen":""}}):_c('div',{class:['preview-file'],style:({
            'background-image': 'url(' + _vm.urlPath + ')'
          })})])],1)],1):_vm._e(),_c('b-modal',{ref:"modalFile",attrs:{"id":"modalFile","hide-header":"","hide-footer":"","centered":"","body-class":"p-4","size":"xl"}},[_c('div',{staticClass:"modal-header border-0 px-0 pt-0"},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.$bvModal.hide('modalFile')}}},[_vm._v(" × ")])]),_c('div',[_c('b-container',{staticClass:"p-0"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"w-100 h-100"},[_c('b-aspect',{attrs:{"aspect":_vm.aspect}},[(['jpg', 'jpeg', 'png'].indexOf(_vm.fileTypeDisplay) >= 0)?_c('div',{class:['preview-file'],style:({
                    'background-image': 'url(' + _vm.urlPath + ')'
                  })}):(['pdf'].indexOf(_vm.fileTypeDisplay) >= 0)?_c('embed',{attrs:{"src":_vm.urlPath,"type":"application/pdf","frameBorder":"0","scrolling":"auto","height":"100%","width":"100%","allowfullscreen":""}}):_vm._e()])],1)])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }