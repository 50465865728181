<template>
  <b-modal
    v-model="isModalShow"
    title="Assign To"
    hide-footer
    @hide="hide"
    size="lg"
  >
    <div>
      <InputText
        v-model="filter.search"
        textFloat="Search Admin"
        placeholder="Staff ID, Email"
        isRequired
        type="text"
        icon="search"
        @submit="onSearchStaff"
        @onkeyup="handleKeyup"
      />
      <!-- <div class="text-center">
        <b-button variant="main-primary" @click="onSearchStaff">
          Comfirm
        </b-button>
      </div> -->
      <div class="panel-table">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="filter.perPage"
          :busy="isBusy"
          show-empty
          empty-text="Empty Data"
          responsive
          class="table-main"
        >
          <template v-slot:cell(ids)="data">
            <b-button variant="main-primary" @click="onSelectStaff(data.item)">
              Select
            </b-button>
          </template>
          <template v-slot:cell(firstname)="data">
            {{ data.item.firstname }}
            {{ data.item.lastname }}
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-row class="no-gutters">
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-md-start"
          >
            <p class="my-2 my-md-0">
              Showing {{ showing === 1 ? showing : showing + 1 }} to
              {{ showingTo }} of {{ rows | numeral("0,0") }} Entries
            </p>
          </b-col>
          <b-col md="6" class="d-md-flex text-center">
            <b-pagination
              v-model="filter.pageNo"
              :total-rows="rows"
              :per-page="filter.perPage"
              class="pagination-main mb-2 mb-md-0"
              @change="handlePagination"
              align="center"
            ></b-pagination>
            <b-form-select
              class="ml-md-3 w-50"
              v-model="filter.perPage"
              @change="handleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  name: "ChatBoxModalAssignTo",
  props: {
    chatRoomId: {
      required: false,
      type: Array
    }
  },
  components: {
    InputText
  },
  data() {
    return {
      filter: {
        search: "",
        pageNo: 1,
        perPage: 10,
        brandId: 0,
        userGUID: ""
      },
      forceRefresh: true,
      isBusy: false,
      loadFlag: false,
      isModalShow: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      items: [],
      fields: [
        {
          key: "ids",
          label: "",
          class: "col-ids"
        },
        {
          key: "id",
          label: "Staff ID",
          class: "text-center col-id"
        },
        {
          key: "firstname",
          label: "Name",
          thClass: "text-center col-name"
        },
        {
          key: "email",
          label: "Email",
          thClass: "text-center col-email"
        }
      ],
      pageOptions: [
        { value: 10, text: `10 / Page` },
        { value: 30, text: `30 / Page` },
        { value: 50, text: `50 / Page` },
        { value: 100, text: `100 / Page` }
      ],
      timer: null
    };
  },
  computed: {
    ...mapGetters({
      brandId: "getBrand"
    })
  },
  methods: {
    hide() {
      this.isModalShow = false;
    },
    show() {
      this.isModalShow = true;
      this.clearItems();
      this.getStaff();
      // this.onSearchStaff();
    },
    clearItems() {
      this.filter.search = "";
      this.filter.pageNo = 1;
      this.filter.perPage = 10;
      this.showing = 1;
      this.showingTo = 10;
      this.rows = 0;
      this.items = [];
    },
    onSearchStaff() {
      this.getStaff();
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    getStaff: async function() {
      this.isBusy = true;

      this.filter.brandId = this.brandId;
      this.filter.userGUID = this.$store.state.chatAdminProfile.externalId;
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/staff/${this.brandId}`,
        this.filter
      );
      if (response.data.result) {
        this.items = response.data.detail.staffList;
        this.rows = response.data.detail.count;
      }

      this.isBusy = false;
    },
    onSelectStaff(item) {
      this.assignToStaff(item);
      // this.$emit("selectedStaff", item);
      // this.hide();
    },
    async assignToStaff(item) {
      let body = {
        chatRoomId: this.chatRoomId,
        externalId: item.externalId,
        brandID: this.brandId
      };
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/staff/update`,
        body
      );
      if (response.data.result) {
        this.hide();
      }
    },
    handlePagination(pageNo) {
      this.filter.pageNo = pageNo;
      if (pageNo === 1) {
        this.showing = this.filter.pageNo;
        this.showingTo = this.filter.perPage;
      } else {
        this.showing = pageNo * this.filter.perPage - this.filter.perPage;
        this.showingTo = this.showing + this.filter.perPage;
      }
      this.getStaff();
    },
    handleChangePerpage(perPage) {
      this.filter.pageNo = 1;
      this.filter.perPage = perPage;
      this.showing = this.filter.pageNo;
      this.showingTo = this.filter.perPage;
      this.getStaff();
    },
    handleKeyup() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.onSearchStaff();
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
::v-deep .col-ids {
  width: 105px !important;
  max-width: 105px !important;
  button {
    min-width: 80px;
  }
}
@media (max-width: 767.98px) {
  ::v-deep .table-main {
    .col-name {
      min-width: 190px;
    }
  }
}
</style>
