<template>
  <div>
    <div class="content-page">
      <!-- <Header v-if="$route.path !== '/login'" /> -->
      <ChatPanel
        :isShowBoth="!isMobile"
        @set-loading="val => (isLoading = val)"
      />
      <ModalPanelGloabal />
      <b-modal
        v-model="isShowModalOverLay"
        no-fade
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <img
            src="@/assets/images/loading.svg"
            alt="loading"
            class="my-2 loading-icon"
          />
          <div class="textc-primary">กรุณารอสักครู่</div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ChatPanel from "@/components/chat/ChatPanel";
// import Header from "@/views/Layouts/Header.vue";
import ModalPanelGloabal from "@/components/modal/alert/ModalPanelGloabal";
import { mapGetters } from "vuex";
export default {
  name: "chat-index",
  components: {
    // Header,
    ChatPanel,
    ModalPanelGloabal
  },
  data() {
    return {
      isLoading: true,
      profile: {
        chatId: "",
        name: "",
        email: "",
        photoUrl: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      isShowModalOverLay: "getIsLoading",
      isMobile: "getIsMobile"
    })
  },
  methods: {
    handleGetSocialList() {
      fetch(`${this.$chatBaseUrl}/brand/social`, {
        method: "GET",
        headers: new Headers(this.$headersChat)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result == 1) {
            this.$store.commit("UPDATE_CHAT_SOCIAL_LIST", result.detail);
            this.$store.dispatch("setChatSocialChannelList", result.detail);
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  },
  mounted() {},
  created: async function() {
    // if (!this.$cookies.isKey("d-order-session-token")) {
    //   this.$router.push({ path: "/" });
    //   // window.location.href = this.$backofficeUrl + "/login";
    // }
    // this.$isLoading = true;
    // this.getAdminProfile();
    await this.$store.dispatch("setConversationTopicList");
    await this.$store.dispatch("getChatAdminProfile");
    await this.$store.dispatch("handleTierList", this.$route.query.brandID);
    this.$store.commit("setUsername", this.$cookies.get("d-order-username"));

    this.handleGetSocialList();
    setInterval(() => {
      if (!this.$store.state.isSocketOpen) {
        this.$store.dispatch("handleChatSocket", {
          brandID: this.$route.query.brandID,
          chatAdminProfile: this.$store.state.chatAdminProfile
        });
      }
    }, 2000);
  }
};
</script>

<style scoped>
.content-page {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  height: calc(100vh - 51px);
}

@media (max-width: 767.98px) {
  .content-page {
    background-color: white;
    /* position: fixed; */
    top: 0px;
    width: 100%;
    height: 100%;
    /* z-index: 1010; */
  }
}
</style>
